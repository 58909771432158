<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <a-card>
                <div class="my-md">
                    <a-tabs default-active-key="1" @change="onchange">
<!--                        <a-tab-pane key="1" :tab="l('班次')" forceRender>-->
<!--                            <shiftinfo ref="shift"></shiftinfo>-->
<!--                        </a-tab-pane>-->
                        <a-tab-pane key="2" :tab="l('考勤周期')" forceRender>
                            <attendance-setting ref="attSetting"></attendance-setting>
                        </a-tab-pane>
                        <a-tab-pane key="3" :tab="l('考勤机配置')" forceRender>
                            <a-row>
                                <a-col :span="3">
                                    <a-form-item required :label="l('启用考勤机：')"></a-form-item>
                                </a-col>
                                <a-col :span="8" style="padding-top: 6px;">
                                    <a-switch
                                        checked-children="启用"
                                        un-checked-children="关闭"
                                        default-checked
                                        v-model="entity.enable"
                                    />
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="3">
                                    <a-form-item required :label="l('对接URL：')"></a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-input :placeholder="l('AddressName')" v-model="entity.dockingUrl" />
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="3">
                                    <a-form-item required :label="l('密钥：')"></a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-input :placeholder="l('AddressName')" v-model="entity.dockingKey" />
                                </a-col>
                            </a-row>
                            <a-row style="padding-left: 20%;">
                                <a-button :type="'primary'" @click="handleSubmit">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="4" :tab="l('加班阈值')" forceRender>
                            <a-row>
                                <a-col style="padding-left: 20px;padding-top: 10px">
                                    最大加班时数:
                                    <a-input-number  v-model="maxOverTimeNumber" :min="0" />
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col style="padding-left: 20px;padding-top: 10px">
                                    提醒起始值:
                                    <a-input-number  v-model="maxOverTimeStartNum" :min="0" />
                                </a-col>
                            </a-row>
                            <a-row style="padding: 50px;">
                                <a-button :type="'primary'" @click="saveOverTimeNumber">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="5" :tab="l('员工自助确认')" forceRender>
                            <a-row>
                                <a-col :span="3">
                                    <a-form-item  :label="l('是否启用：')"></a-form-item>
                                </a-col>
                                <a-col :span="10" style="padding-top: 6px;">
                                    <a-switch
                                        checked-children="启用"
                                        un-checked-children="关闭"
                                        default-checked
                                        v-model="isEnable"
                                    /> 
                                    <span style="margin-left: 10px;font-size: 8px;color: #bfbfbf;">启用后，考勤过数必须要员工已确认或超时。</span>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="3">
                                    <a-form-item  :label="l('确认超时天数：')"></a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-input-number  v-model="sureOverTimeDays" :min="0" />
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="3">
                                    <a-form-item  :label="l('超时提示文案：')"></a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-textarea :placeholder="l('维护超时提示文案')" v-model="overTimePromptCopy" />
                                </a-col>
                            </a-row>
                            <a-row style="padding-left: 20%;">
                                <a-button :type="'primary'" @click="saveStaffSelfConfirmation">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>
                            </a-row>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { AppComponentBase } from '../../../shared/component-base';
import {
    CreateOrUpdateAttMachineDeployInput,
    AttMachineDeployEditDto,
    AttMachineDeployServiceProxy,
    TenantSettingsServiceProxy,
} from '../../../shared/service-proxies';
import shiftinfo from '../shiftinfo/shiftinfo';
import AttendanceSetting from '../attendance-setting/attendance-setting';

export default {
    name: 'attmachinedeploy',
    mixins: [AppComponentBase],
    components: { shiftinfo, AttendanceSetting },
    data() {
        return {
            zh_CN,
            spinning: false,
            value: 26,
            entity: new AttMachineDeployEditDto(),
            maxOverTimeNumber: 0,
            maxOverTimeStartNum: 0,
            isEnable: false,
            overTimePromptCopy: "",
            sureOverTimeDays:0,
        };
    },
    created() {
        this._attMachineDeployServiceProxy = new AttMachineDeployServiceProxy(this.$apiUrl, this.$api);
        this._tenantSettingsServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.spinning = true;
            this._attMachineDeployServiceProxy
                .getForEdit()
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.entity = res.attMachineDeploy;
                });
        },
        getMaxOverTimeNumber() {
            this.spinning = true;
            this._tenantSettingsServiceProxy
                .getMaxOverTimeNumber()
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.maxOverTimeNumber = res.maxNumber;
                    this.maxOverTimeStartNum = res.startNum;
                    
                });
        },
        getStaffSelfConfirmation() {
            this.spinning = true;
            this._tenantSettingsServiceProxy
                .getStaffSelfConfirmation()
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.isEnable = res.isEnable;
                    this.overTimePromptCopy = res.overTimePromptCopy;
                    this.sureOverTimeDays=res.sureOverTimeDays;
                });
        },
        onchange(v) {
            switch (v) {
                case '1':
                    this.$refs.shift.getData();
                    break;
                case '2':
                    this.$refs.attSetting.getData();
                    break;
                case '3':
                    this.getData();
                    break;
                case '4':
                    this.getMaxOverTimeNumber();
                    break;
                case '5':
                    this.getStaffSelfConfirmation();
                    break;
            }
        },
        handleSubmit() {
            let input = new CreateOrUpdateAttMachineDeployInput();
            if (this.entity.enable) {
                if (!this.entity.dockingUrl || !this.entity.dockingUrl) {
                    return abp.message.warn('启用考勤机请填写对接URL和密钥!');
                }
            }
            input.attMachineDeploy = this.entity;
            this.spinning = true;
            this._attMachineDeployServiceProxy
                .createOrUpdate(input)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.$notification.success({
                        description: undefined,
                        message: this.l('SavedSuccessfully'),
                    });
                    this.getData();
                });
        },
        saveOverTimeNumber() {
            this.spinning = true;
            this._tenantSettingsServiceProxy
                .updateMaxOverTimeNumber(this.maxOverTimeNumber, this.maxOverTimeStartNum)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.$notification.success({
                        message: this.l('SavedSuccessfully'),
                    });
                });
        },
        saveStaffSelfConfirmation() {
            if(this.isEnable){
                if(this.sureOverTimeDays<=0){
                    return abp.message.warn('启用员工自助确认后超时天数不能小于等于0!');
                }
                if(!this.overTimePromptCopy){
                    return abp.message.warn('启用员工自助确认请填写超时提示文案!');
                }
            }
            this.spinning = true;
            this._tenantSettingsServiceProxy
                .updateStaffSelfConfirmation(this.isEnable, this.overTimePromptCopy,this.sureOverTimeDays)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.$notification.success({
                        message: this.l('SavedSuccessfully'),
                    });
                });
        },
    },
};
</script>

<style scoped></style>
